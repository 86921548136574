@use '../../../../Layouts/AuthLayout/layoutConstants';

// @media print {
//   $backgroundGray: #dcdfe5;

//   .tab-content {
//     margin: -1px 0 20px 0;
//     border: 1px solid $backgroundGray;
//     padding: 0 10px 10px 10px;

//     .table-items {
//       .dnd-item {
//         .actions {
//           display: flex;
//         }
//       }
//     }

//     .end-of-tab {
//       .signature-block {
//         .d-flex {
//           display: none;
//         }
//       }
//     }
//   }
//   .proposal-tab {
//     align-self: center;
//     background-color: $backgroundGray;
//     border: 1px solid $backgroundGray;
//     border-radius: 6px 6px 0 0;
//     align-content: center;
//     height: 40px;
//     min-width: 20px;
//     text-align: center;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     padding: 0 8px 0 8px;
//     cursor: pointer;
//     div {
//       display: flex;
//       align-items: center;
//       width: 100%;
//       height: 90%;
//       justify-content: center;
//     }
//     &.active {
//       background-color: white;
//       border-bottom-color: white;
//       z-index: 1;
//     }
//   }
//   /*
//   .media-section {
//     display: flex;
//     justify-content: flex-start;
//     flex-direction: row;
//     flex-wrap: wrap;
//     .media {
//       height: 150px;
//       width: 150px;
//       margin: 10px;
//       position: relative;
//       img {
//         height: 100%;
//         width: 100%;
//       }
//       button {
//         position: absolute;
//         top: 5%;
//         right: 5%;
//       }
//     }
//   }
//   .instalation {
//     display: flex;
//     justify-content: flex-end;
//     margin: {
//       top: 20px;
//       bottom: 20px;
//     }
//     label {
//       display: flex;
//     }
//     span {
//       align-self: center;
//       margin-right: 20px;
//     }
//   }

//   .investment-total {
//     height: 40px;
//     background-color: #dcdfe5;
//     display: flex;
//     justify-content: space-between;
//     padding: 0 24px;
//     h5 {
//       align-self: center;
//       font-size: 14px;
//       margin: 0;
//     }
//     span {
//       align-self: center;
//     }
//   }
//   .table-items {
//     margin: 10px 0 10px;
//   }
//   .form-custom {
//     .actions-modals {
//       display: flex;
//       .action {
//         cursor: pointer;
//         text-align: center;
//         .mdi {
//           font-size: 20px;
//         }
//         .fas {
//           font-size: 20px;
//           margin-top: 8px;
//         }
//         .far {
//           font-size: 20px;
//           margin-top: 8px;
//         }
//       }
//     }
//     .first-row {
//       display: flex;
//       width: 100%;
//       justify-content: center;
//       margin-top: 20px;
//       margin-bottom: 20px;
//       .proposal-notes {
//         width: 50%;
//       }
//       .actions-modals {
//         align-self: flex-end;
//         width: 50%;
//         display: flex;
//         justify-content: space-around;
//       }
//     }
//     .second-row {
//       .align-self-center {
//         .tags {
//           display: flex;
//           flex-wrap: wrap;
//           margin-bottom: 30px;
//           max-height: 100px;
//           overflow-y: auto;
//           .tag {
//             padding: 10px;
//             height: 35px;
//             position: relative;
//             background: cornflowerblue;
//             margin: 4px;
//             align-self: center;
//             text-align: center;
//             vertical-align: middle;
//             border-radius: 5px;
//             font-size: 14px;
//             line-height: 16px;
//             color: white;
//             button {
//               position: absolute;
//               padding: 0;
//               background: none;
//               border: none;
//               top: 0px;
//               right: 0px;
//               color: white;
//               width: 16px;
//               height: 16px;
//               line-height: 16px;
//               border-radius: 20px;
//             }
//           }
//         }
//       }

//       display: flex;
//       width: 100%;
//       .installer-notes {
//         width: 50%;
//       }
//     }
//     .proposal-tags-container {
//       display: flex;
//       justify-content: space-between;
//       width: 40%;
//     }
//     .proposal-tags {
//       align-self: flex-start;
//       width: 60%;
//       .tags {
//         display: flex;
//         flex-wrap: wrap;
//         margin-bottom: 30px;
//         max-height: 100px;
//         overflow-y: auto;
//         .tag {
//           padding: 10px;
//           height: 35px;
//           position: relative;
//           background: cornflowerblue;
//           margin: 4px;
//           align-self: center;
//           text-align: center;
//           vertical-align: middle;
//           border-radius: 5px;
//           font-size: 14px;
//           line-height: 16px;
//           color: white;
//           button {
//             position: absolute;
//             padding: 0;
//             background: none;
//             border: none;
//             top: 0px;
//             right: 0px;
//             color: white;
//             width: 16px;
//             height: 16px;
//             line-height: 16px;
//             border-radius: 20px;
//           }
//         }
//       }
//     }
//   }
//   .end-of-system-table {
//     width: 100%;
//     tr > td:nth-child(1) {
//       width: 30%;
//     }
//     tr > td:nth-child(2) {
//       width: 10%;
//     }
//     tr > td:nth-child(3) {
//       width: 15%;
//       text-align: left;
//     }
//     tr > td:nth-child(4) {
//       width: 25%;
//       text-align: center;
//     }
//     .rebates {
//       vertical-align: top;
//     }
//     .instants {
//       height: calc(100% + 60px);
//       vertical-align: top;
//     }
//   } */
// }

///////////////////redesign
.proposal-body {
  // background: #f2f3fe;
  padding: 10px;
  border-radius: 4px;

  .pay_description_box {
    display: flex;
    align-items: flex-end;

    .pay_description {
      color: #191919;
      font-weight: bold;
      font-size: 14px;
    }

    button {
      margin-left: 30px;
    }

    @media (max-width: 499px) {
      margin-bottom: 10px;
    }
  }

  .proposal-contacts {
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 25px 40px;
    line-height: 21px;
    background-color: #fff;

    & > div {
      display: flex;
      flex-wrap: wrap;

      & > *:not(span) {
        flex: 0 0 24%;
        margin-bottom: 15px;
        padding-right: 10px;
      }
    }

    &__from {
      div {
        &:nth-last-of-type(2) {
          margin-right: auto;
        }
      }
    }

    .user-contact {
      .contacts-table {
        &__row {
          display: flex;

          p {
            &:nth-child(1) {
              flex: 0 0 50px;
            }
          }
        }
      }
    }

    &__to {
      flex-wrap: wrap;
      width: 100%;
      & > .user-contact {
        flex: 1 1 23%;
        margin-bottom: 10px;

        @media (max-width: 499px) {
          flex: 0 1 46%;
        }
      }
      @media (min-width: 1081px) {
        .user-contact:not(:nth-child(4n)):not(:last-child) {
          border-right: 1px solid #d6d6d6;
          margin-right: 10px;
        }
      }
      @media (min-width: 769px) and (max-width: 1080px) {
        .user-contact:not(:nth-child(3n)):not(:last-child) {
          border-right: 1px solid #d6d6d6;
          margin-right: 10px;
        }
      }
      @media (max-width: 499px) {
        .user-contact:not(:nth-child(2n)):not(:last-child) {
          border-right: 1px solid #d6d6d6;
          margin-right: 10px;
        }
      }
    }
  }

  .proposal-contacts-information {
    margin-bottom: 20px;
  }

  .miscellaneous-items {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;

    p:nth-child(1) {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.01em;
      color: #191919;
    }

    p:nth-child(2) {
      font-size: 12px;
      flex: 0 0 76px;
    }

    @media (max-width: 960px) {
      p:nth-child(2) {
        font-size: 10px;
        flex: 0 0 50px;
      }
    }

    @media (max-width: 499px) {
      p:nth-child(2) {
        font-size: 8px;
        flex: 0 0 40px;
      }
    }
  }

  /*   .proposal-tabs {
    .proposal-tab {
      background-color: #fbfdff;
      opacity: 0.5;
      margin-right: 10px;

      &.active {
        opacity: 1;
        font-weight: 500;
      }
    }
  } */

  .tab-content {
    // background: #fbfdff;
    .proposal-preview-divider {
      &::after,
      &::before {
        border-color: #191919;
      }
    }

    // .signature-block {
    //   margin-bottom: 50px;
    // }

    .section {
      .section-header {
        background: #f7f7f7;
        border-radius: 4px;
        padding: 5px;
        margin-left: -5px;
        font-size: 18px;
        font-weight: 600;

        &__descr {
          font-size: 16px;
          margin-bottom: 7px;
          white-space: pre-wrap;

          @media (max-width: 499px) {
            font-size: 8px;
          }
        }
      }

      .dnd-items-header {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.01em;
        color: #191919;
      }

      .dnd-items-container {
        .dnd-item {
          .description {
            /*  flex: 2 1 180px;
            font-size: 14px; */

            @media (max-width: 960px) {
              font-size: 10px;
            }

            @media (max-width: 499px) {
              font-size: 8px;
              // flex: 1 0 60px;
              // flex: 0 0 120px;
            }
          }
          .date {
            // flex: 1 0 150px;
            // margin-right: 0;
            // width: 150px;

            // @media (max-width: 960px) {
            //   flex: 1 0 80px;
            // }

            // @media (max-width: 499px) {
            //   flex: 1 1 40px;
            // }
          }
        }
      }
    }

    .end-of-tab {
      // margin-top: 30px;
      background-color: #fff;
      // border: 1px solid #dcdfe5;
      // border-radius: 4px;
      padding: 15px 10px;

      @media (max-width: 499px) {
        padding: 10px;
      }
    }
  }

  @media (max-width: 499px) {
    padding: 5px;
  }
}
/* 
.proposal-form-information_for_customer {
  .proposal-form-tabs_content-info_block-installers_info {
    border: none;
  }
} */

/* .proposal-form-information_from_customer,
.proposal-form-information_for_customer {
  .proposal-form-tabs_content-info_block-installers_info {
    border: none;
    margin-top: 0;

    .files {
      flex-basis: 100%;

      .proposal-form-tabs_content-info_block-installers_info-file {
        margin-left: 0;
        margin-right: 15px;
      }
    }

    .field-label,
    .placeholder {
      display: none;
    }
  }
} */

.proposal-to-print {
  position: relative;
  padding-bottom: 20px;

  .proposal-form-intallation_dates-helper_text > td > div.proposalStatus {
    font-size: 12px !important;
  }
  .proposal-contacts__to-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 !important;
    margin-bottom: 0 !important;
    span {
      width: 100%;
    }
  }
  .proposal-install__dates {
    @extend %settings;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    margin-right: 5px;
    background-color: #4c53ef1a;
    width: 280px !important;
    height: 185px;
    border-radius: 4px;
    padding: 10px !important;
    .proposal-form-installation_dates-table {
      td {
        padding: 2.5px 15px;
      }
    }
    & > .ant-checkbox-wrapper {
      font-size: 12px;
    }
  }
  .logos {
    .print-logo {
      flex: 1 1 100px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &:not(:last-child) {
        margin-right: 5px;
      }

      &.main {
        zoom: 150%;
      }
    }
  }

  .proposal-form-information_from_customer,
  .proposal-form-information_for_customer {
    .proposal-form-tabs_content-info_block-installers_info {
      border: none;
      margin-top: 0;

      div:first-child {
        max-width: 100%;
      }

      .files {
        flex-basis: 100%;

        .proposal-form-tabs_content-info_block-installers_info-file {
          // margin-left: 0;
          // margin-right: 15px;
          margin: 0 10px 15px 0;
        }
      }

      .field-label,
      .placeholder {
        display: none;
      }
    }
  }

  @media (max-width: 499px) {
    // overflow-x: auto;
    .logos {
      .print-logo {
        height: 40px;
      }
    }

    .proposal-body {
      .proposal-contacts {
        padding: 5px;
        font-size: 8px;
        line-height: 1.5;

        &__to {
          p {
            line-height: 1.5;
          }
        }

        .user-contact .contacts-table__row p:nth-child(1) {
          flex: 0 0 30px;
        }
        .user-contact .contacts-table__row p:nth-child(2) {
          white-space: nowrap;
        }

        // .d-flex {
        //   .proposal-contacts__to-wrapper {
        //     padding: 0 10px;
        //   }
        // }
      }

      .tab-content {
        .form-custom {
          & > div {
            margin-bottom: 10px;
          }
        }

        .section {
          .dnd-items-container {
            padding-left: 0;
            font-size: 8px;

            .dnd-item {
              /*  & > div:not(:last-child) {
                margin-right: 20px;

                @media (max-width: 499px) {
                  margin-right: 5px;
                }
              } */

              &.item-option {
                & > span {
                  padding: 2px 2px 2px 0;
                }
              }
            }
          }
        }

        .customer-block {
          margin-top: 20px;

          &__images {
            flex-wrap: wrap;

            .customer-block__image {
              margin-right: 10px;
              margin-bottom: 10px;

              & > div {
                width: 60px !important;

                &:nth-child(1) {
                  height: 60px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .powered-by {
    position: absolute;
    right: 10px;
    bottom: -5px;
    opacity: 0.6;
    font-size: 12px;

    img {
      width: 20px;
      position: relative;
      top: -2px;
    }
  }
}

.payment-block {
  .balance-due {
    font-size: 1.1rem;
  }
}
