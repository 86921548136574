@use '../../../../Layouts/AuthLayout/layoutConstants';

$errorColor: #f5222d;
.with-download-popap {
  .media-popap {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 100%;
    left: 50%;
    background: rgb(241, 241, 241);
    padding: 20px;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    //z-index: 9999;
    transform: translate(-50%, -50px);
    .error-message {
      color: $errorColor;
      font-size: 0.875rem;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: -10px;
      transform: rotate(45deg);
      background-color: rgb(243, 243, 243);
    }
    /* .ant-upload-drag {
      transition: all 0.3s;
    }
    .ant-upload-drag:hover {
      background: #c5dcf9;
    } */
  }

  .media-popap-show {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -15px);
  }
}

/* .media-popap {
    position: absolute;
    top: 100px;
  } */

.media-popap {
  @extend %settings;
}
