@use '../../../../cropBlock';

.user-form-profile-picture {
  @extend %cropBlockSettings;
  .company-drag-logo-empty,
  .company-drag-wrapper {
    margin: 0 auto 1.5rem;
  }
  // .ant-upload.ant-upload-drag {
  //   border-radius: 50%;
  // }
}

.user-form-profile-picture.round {
  .ant-upload.ant-upload-drag {
    border-radius: 50%;
  }
}
.user-form-profile-picture.rect {
  .ant-upload.ant-upload-drag {
    border-radius: 0;
  }
}
