@use '../../../Layouts/AuthLayout/layoutConstants';

$errorColor: red;
.manual-address-container {
  @extend %settings;
  @extend %mainButton;
  &-map {
    width: 100%;
    margin: 1rem 0 1.5rem;
    path.leaflet-interactive {
      stroke-width: 1;
      fill-opacity: 0.1;
    }
  }
  .leaflet-container {
    width: 100%;
    height: 290px;
    z-index: 1;
  }
  &-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    button:first-of-type {
      margin-right: 30px;
    }
    /* .btn-primary {
      background: #edeffe;
      border-radius: 4px;
      border: 1px solid #edeffe;
      color: #4285f4;
    } */
  }
  &-costom-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #000000;
    opacity: 0.87;
    //margin-bottom: 1rem;
  }
  &-error {
    color: $errorColor;
  }
  &-map-error {
    border: 1px solid $errorColor;
  }
}
