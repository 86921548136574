// @import '../../../../Proposals/components/Modals/ModalProposalTemplateLoad/ModalProposalTemplateLoadConstants';
@use '../../../../Layouts/AuthLayout/layoutConstants';
//@import '../../../cropBlock';

.user-form {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  @extend %settings;
  @extend %mainButton;
  //@extend %cropBlockSettings;
  .leaflet-container {
    z-index: 0;
  }
  .commission-type_placeholder {
    position: absolute;
    top: 4px;
    left: 24px;
  }

  /* .company-drag-logo-empty,
  .company-drag-wrapper {
    margin: 0 auto 1.5rem;
  }
  .ant-upload.ant-upload-drag {
    border-radius: 50%;
  } */

  .main_button .cancel {
    color: #191919;
    margin-right: 10px;
  }
  .main_button.cancel.ant-btn:hover {
    color: #4285f4;
    border: 1px solid #4285f4;
    border-color: #4285f4;
  }

  .use-vertical-sidebar {
    margin-bottom: 1rem;
    .ant-checkbox-wrapper {
      color: #191919;
    }
  }

  .use-start-location {
    margin-bottom: 1rem;
    .ant-checkbox-wrapper {
      color: #191919;
    }
  }

  .custom-address-autocomplete-wrapper > div > .orcatec-input {
    & > label {
      font-size: 14px;
    }
  }
  .modules {
    &-label {
      color: #191919;
    }
    .ant-checkbox-wrapper {
      white-space: nowrap;
    }
  }
  .user-number_prefix {
    label {
      font-size: 14px;
    }
  }
  .info-icon {
    font-size: 20px;
    color: #2e5bff;
    cursor: pointer;
    align-self: center;
    position: absolute;
    right: 5px;
    top: 6px;
    width: 20px;
    height: 20px;
    z-index: 1;

    @media (max-width: 500px) {
      top: 3px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  .ant-checkbox-wrapper {
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
  }
  .custom-select-outlined {
    // @extend %customSelectOutlined;
    .MuiInputBase-input {
      font-size: 16px;
      line-height: 24x;

      letter-spacing: 0.01em;

      color: #191919;
    }
  }
  /* .ant-row {
    margin-bottom: 10px;
  } */
  .ant-checkbox-group {
    .ant-col {
      margin: 5px 7px 5px 0;
      width: auto;
    }
  }
  .ant-form-item-required {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
  .user-cancel-btn {
    color: #191919;
    border: 1px solid #d6d6d6;
    margin-right: 10px;
    height: 30px;
  }
  .user-cancel-btn:hover {
    background-color: #e9e9e9;
    color: #191919;
    border: 1px solid #d6d6d6;
  }
  .change-password-link {
    margin-bottom: 1rem;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4285f4;
    text-align: start;
  }
  .change-password-link:hover {
    color: #3a40b4;
  }
  .user-change-password-form {
    width: 100%;
    padding: 1.5rem;
    border: 1px solid rgba(196, 196, 196, 0.15);
    box-sizing: border-box;
    margin-bottom: 1rem;
    .user-change-password-form-header {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #000000;
      margin-bottom: 1rem;
      text-align: center;
    }
    .buttons-block {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      width: 228;
      letter-spacing: 0.01em;
      & > button:first-child {
        color: rgba(0, 0, 0, 0.5);
      }
      & > button:last-child {
        color: #4285f4;
      }
    }
  }
  .user-cgange-password-success-message {
    width: 100%;
    height: 0;
    margin-bottom: 0;
    transition: height 0.3s, margin-bottom 0.3s;
    overflow-y: hidden;
    &.show {
      margin-bottom: 1rem;
      height: 220px;
    }
  }
}

//form button {
//  margin-bottom: 1rem;
//}

.invite-link {
  position: relative;

  .MuiTextField-root .MuiOutlinedInput-root input {
    padding-right: 30px;
  }

  .anticon-copy {
    position: absolute;
    top: 6px;
    right: 6px;

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      color: #4285f4;
    }
  }

  &.copied {
    &:after {
      position: absolute;
      content: 'Copied to clipboard!';
      font-size: 10px;
      right: -90px;
      bottom: 6px;
    }

    .anticon-copy {
      color: #4285f4;
    }
  }
}

.company-user-map {
  //margin-top: 735px;
  .leaflet-container {
    width: 100%;
    height: 300px;
  }
}

.info-icon-tooltip {
  width: 164px;
  .ant-tooltip-arrow {
    right: 2px !important;
  }

  .ant-tooltip-inner {
    // font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 13px;
    letter-spacing: 0.01em;
  }
}
