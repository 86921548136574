@use 'mui-styles';
@import 'cropperjs/dist/cropper.css';

// Scrollbar styles:

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  width: 8px;
  border-radius: 10px;
  background-color: transparent;
  -webkit-box-shadow: transparent;

  &:hover {
    #{body}::-webkit-scrollbar-thumb {
      display: block;
    }
  }
}

::-webkit-scrollbar-thumb {
  //display: none;
  border-radius: 8px;
  //background-color: #4c53efc2;
  background-color: #4c53ef4a;

  &:hover {
    background-color: #4285f4c2;
  }

  &:horizontal {
    display: block;
  }
}

.no-scroll {
  overflow: hidden !important;
}

body {
  // overflow: unset !important;
  padding-right: 0 !important;
}

html {
  overflow: unset !important;

  @media (max-width: 499px) {
    font-size: 12px;
  }
}

p {
  margin: 0;
}

input {
  background-color: white;
}

button {
  cursor: pointer;
}

main {
  position: relative;
}

.anticon {
  vertical-align: text-top;
}

.page-header {
  text-align: right;
  border-bottom: grey solid 2px;
  margin-bottom: 30px;

  .btn {
    vertical-align: middle;
    margin-bottom: 0;
  }

  h3 {
    text-align: left;
    margin: 0;
  }
}

.table-items-list {
  th {
    font-weight: bold;
    padding: 15px !important;
    font-size: 11px;
  }

  td {
    span.badge {
      margin-left: 20px;
    }
  }
}

.btn-custom-info {
  font-weight: 500;
  background-color: #4285f4;
  color: white;

  &:hover {
    background-color: #1a08b1;
    color: white;
  }
}

.btn-custom-cancel {
  font-weight: 500;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  color: #191919;
}

.button-custom-secondary {
  font-weight: 500;
  background-color: #edeffe;
  color: #4285f4;
}

.button-custom-delete {
  font-weight: 500;
  background-color: #ffffff;
  color: #191919;
  border: 1px solid #ed6566;
}

.table-actions {
  //font-size: 18px;
  //font-weight: bold;

  .mdi-close-circle-outline {
    color: red;

    &:hover {
      color: white;
    }
  }

  .btn-outline-danger {
    &:hover {
      .mdi-close-circle-outline {
        color: white;
      }
    }
  }
}

.inActivModal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.inActivBody {
  height: 35%;
  width: 50%;
  background: white;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .inactiveTextWrapper {
    width: 45%;

    span {
    }
  }
}

.inActiveButtons {
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  width: 80%;

  button {
    border: 0;
    width: 140px;
  }
}

body {
  background-color: #f8f8fa;
}

.subscription-modal {
  font-weight: 500;

  .modal-header {
    padding: 10px 10px 0;
    border: none;
  }

  .pay-button {
    margin: 0 auto 20px;
    text-align: center;
  }

  .additional-inforation {
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    padding: 10px 20px;
    text-align: center;
    margin: 30px 0 50px;
    font-weight: 600;

    span {
      background-color: #e53655;
      color: #fff;
      padding: 2px;
      border-radius: 4px;
    }
  }
}

@media screen and (max-width: 776px) {
  .inActiveButtons {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    button {
      width: 90%;
      margin-top: 10px;
    }
  }
  .inActivBody {
    .inactiveTextWrapper {
      width: 70%;
    }
  }
}

@media only screen and (max-width: 630px) {
  .inActivBody {
    width: 80%;
  }
}

@media only screen and (max-width: 450px) {
}

.card {
  box-shadow: none !important;
}

.ant-popover-inner-content {
  word-break: break-word;
}

.ant-notification {
  z-index: 99999;
}

.drag-line {
  height: calc(100vh - 177px) !important;
  border-left: 2px dashed gray !important;
}
#print_frame {
  width: 0 !important;
  height: 0 !important;
}
.created__popover {
  & .ant-popover-inner-content {
    padding: 0;
  }
}

div.filter-node {
  background-color: #bae7ff !important;
}

.ant-menu-submenu > ul li.settings-sub-menu {
  height: 30px !important;
}

.tox-notification--warning {
  display: none !important;
}

.contacts-modal {
  width: 90% !important;
  @media screen and (min-width: 900px) {
    width: 820px !important;
  }

  // & .ant-modal-header {
  //   padding: 10px;
  //   padding-top: 6px;
  // }
}

.folder-bar_dropdown {
  & li {
    padding: 0;
  }
}

.tox-promotion {
  visibility: hidden;
}
