@use '../../TabsContent/TabSections/TabSection/TabSectionContent/TabSectionContent.scss';

.proposal-form {
  .proposal-form-tabs_content-end_of_system {
    &-subtotal_table,
    &-total_table {
      tbody {
        tr > td {
          @media (max-width: 670px) {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.proposal-form-tabs_content-end_of_system {
  line-height: 1.33;

  &__discounts {
    &-underline--first {
      border-bottom: 1px solid black;
      height: 10px;
    }

    &-underline--second {
      height: 10px;
    }

    &-title {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: #191919;
      position: relative;

      @media (max-width: 499px) {
        font-size: 8px;
      }
    }

    &-subtitle {
      position: absolute;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.01em;
      color: #191919;

      @media (max-width: 500px) {
        position: inherit;
        font-size: 8px;
      }
    }

    &__one-line {
      display: flex;
      align-items: center;

      .proposal-form-tabs_content-end_of_system__discounts-subtitle {
        position: static;
        margin: 0px 0 0 5px;
      }
    }
  }
  &-subtotal_table,
  &-total_table {
    @extend %totalTableSettings;
    margin-bottom: 5px;
    tbody {
      tr > td:first-child {
        text-align: right;

        @media (max-width: 670px) {
          // text-align: left;
          padding-right: 0;
        }
      }
      tr > td:last-child {
        // padding-right: 15px;
        text-align: right;
        width: 150px;

        @media (max-width: 600px) {
          width: 100px;
        }
      }
    }

    @media (max-width: 499px) {
      margin-bottom: 20px;
    }
  }
  &-discounts_table {
    background-color: #ffffff;
    padding: 10px 20px;
    border: 1px solid #dcdfe5;
    border-radius: 4px;
    overflow-x: auto;
    line-height: 1.33;

    & > table {
      width: 100%;
      tr > td:nth-child(1) {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.01em;
        color: #191919;
        width: 30%;
      }
      tr > td:nth-child(2) {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.01em;
        color: #191919;
        opacity: 0.5;
        width: 10%;

        @media (max-width: 499px) {
          font-size: 8px;
        }
      }
      tr > td:nth-child(3) {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        letter-spacing: 0.01em;
        color: #191919;
        width: 15%;
        text-align: left;

        @media (max-width: 499px) {
          font-size: 8px;
        }
      }
      tr > td:nth-child(4) {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #191919;
        width: 25%;
        text-align: end;

        @media (max-width: 499px) {
          font-size: 8px;
        }
      }
      tr > td:nth-child(5) {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #191919;
        text-align: end;

        @media (max-width: 499px) {
          font-size: 8px;
        }
      }
      .rebates {
        vertical-align: top;
      }
      .instants {
        height: calc(100% + 60px);
        vertical-align: top;
      }
    }

    @media (max-width: 499px) {
      padding: 10px;

      & > table {
        // tr {
        //   display: flex;
        //   flex-wrap: wrap;

        //   td {
        //     font-size: 8px;
        //     border: none !important;

        //     &:nth-child(1) {
        //       flex: 0 0 100%;
        //       font-size: 12px;
        //     }
        //     &:nth-child(2) {
        //       font-size: 8px;
        //       flex: 0 1 50px;
        //     }
        //     &:nth-child(3) {
        //       font-size: 8px;
        //       flex: 0 1 100px;
        //     }
        //     &:nth-child(4) {
        //       font-size: 8px;
        //     }
        //     &:nth-child(5) {
        //       font-size: 8px;
        //       margin-left: auto;
        //     }
        //   }
        // }
      }
    }
  }
  &-total_table {
    margin-top: 5px;
    tbody {
      tr > td:first-child {
        text-align: right;

        @media (max-width: 670px) {
          // text-align: left;
          padding-right: 0;
        }
      }

      & > tr:nth-child(2) {
        td {
          @media (max-width: 600px) {
            width: 150px;
          }
          .MuiFormControl-root,
          .MuiTextField-root {
            width: 100px;

            @media (max-width: 425px) {
            }
          }
        }
      }
      tr > td:last-child {
        // text-align: left;
      }
    }

    @media (max-width: 499px) {
      margin-bottom: 10px;

      tbody {
        tr > td {
          font-size: 14px;
        }
      }
    }
  }
  &-tax-table {
    width: 100%;
    tr > td {
      text-align: right;
      text-transform: capitalize;

      &:nth-child(2) {
        font-size: 14px;
        font-weight: 600;
        width: 150px;

        @media (max-width: 499px) {
          width: 100px;
        }
      }
    }
  }
}
