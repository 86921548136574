@use '../../Layouts/AuthLayout/layoutConstants';

$blueColor: #4285f4;
$blueLighterColor: #626ed4;
.custom-table-layout {
  max-width: 100%;
  width: 100%;
  .custom-data-table-length {
    .form-control {
      height: inherit;
    }
  }
  .searchMaterialTextField {
    input {
      height: 29px;
    }
  }
  .clientsTableHead {
    th {
      border-bottom: 1px solid #e5e5e5;
      padding: 40px 16px 0;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      letter-spacing: 0.01em;

      color: #191919;

      opacity: 0.5;
      div {
        max-height: 30px;
      }
    }
  }
  .clientsTableBody {
    /* tr:not(.expanded-row-container) > td {
      padding: 0 8px !important;

      @media (max-width: 768px) {
        padding: 0 4px !important;
      }
    } */
    tr {
      td {
        height: 50px;
        text-align: left;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #191919;
        .table-actions {
          justify-content: center;
        }
        border-bottom: 1px solid #e5e5e5;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:nth-child(6) {
          text-align: center;
        }
      }
    }
  }
  .custom-control-bar {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    label {
      font-weight: 500;
    }
    input,
    select {
      //font-size: 75%;
      //height: calc(1.5em + 0.5rem + 2px);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.5rem;
      display: inline-block;
      width: 100%;
      font-weight: 400;
      //line-height: 1.5;
      color: #495057;
      vertical-align: middle;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none;
      touch-action: manipulation;
    }
  }
  .custom-clients-control-bar {
    padding: 5px;
  }
  .paggination {
    @extend %settings;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .custom-size-changer {
      width: 110px;
      margin-left: 5px;
      .MuiTextField-root .MuiOutlinedInput-inputMarginDense {
        padding-top: 7.22px;
        padding-bottom: 6.02px;
        padding-left: 10px;
        // font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.01em;

        color: #000000;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin-right: 5px;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      min-width: 30px;
      height: 30px;
      color: $blueColor;
      // font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.01em;
      line-height: 35px;
      border-radius: 4px;
      background: rgba(76, 83, 239, 0.15);
      border: 1px solid rgba(76, 83, 239, 0.15);
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      color: #000000;
      background: #ffffff;
    }
    .ant-pagination-jump-prev:hover,
    .ant-pagination-jump-next:hover {
      color: $blueColor;
      background: #ffffff;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background-color: transparent;
      border: none;
      width: 30px;
      height: 30px;
    }
    .ant-pagination-item {
      min-width: 30px;
      height: 30px;
      margin-right: 5px;
      // font-family: Poppins;
      line-height: 30px;
      background-color: #ffffff;
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      outline: 0;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #000000;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .ant-pagination-prev:disabled,
    .ant-pagination-next:disabled,
    .ant-pagination-disabled {
      background: #f9f9f9;
      color: #19191979;
      cursor: default;
    }
    .ant-pagination-disabled .ant-pagination-item-link {
      cursor: default;
    }
    .ant-pagination-item:not(.ant-pagination-item-active):hover,
    .ant-pagination-prev:not(.ant-pagination-disabled):hover > a,
    .ant-pagination-next:not(.ant-pagination-disabled):hover > a {
      border-color: $blueLighterColor;
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      .ant-pagination-item-link-icon {
        color: $blueLighterColor;
      }
    }

    .ant-pagination-item-active {
      background: $blueLighterColor;
      border-color: $blueLighterColor;
      & > a {
        color: #ffffff;
      }
    }
    .ant-pagination-item-active:hover {
      & > a {
        color: white;
      }
    }
    .ant-pagination-item:not(.ant-pagination-item-active):hover {
      background: rgba(76, 83, 239, 0.15);
      border: 1px solid rgba(76, 83, 239, 0.15);
      & > a {
        color: $blueColor;
      }
    }

    @media (max-width: 768px) {
      .ant-pagination {
        .ant-pagination-total-text {
          font-size: 8px;
        }
        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-jump-next {
          min-width: 20px;
          height: 20px;
          font-size: 8px;
          line-height: 18px;

          a.ant-pagination-item-link {
            width: 20px;
            height: 20px;

            i {
              font-size: 10px;
              line-height: 16px;
            }
          }
        }
      }

      .custom-size-changer {
        width: 80px;

        .MuiFormControl-root {
          margin: 5px 0;

          .MuiSelect-root {
            padding: 2px 5px !important;
            font-size: 8px !important;
          }

          svg.MuiSelect-iconOutlined {
            right: 4px;
            top: 2px;
          }
        }
      }
    }
  }
  .custom-table-border {
    th {
      border-bottom: 1px solid #dee2e6;
    }
    tr {
      border-bottom: 1px solid #dee2e6;
    }
  }
  .custom-table-empty {
    background-color: #f2f3fe;
  }
  & > .custom-table {
    /*  overflow-x: auto;
    overflow-y: hidden;
    width: 100%; */
    & > table {
      margin-top: 10px;
      // max-width: 1830px;
      border-spacing: 0 10px;
      //border-collapse: separate;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 100%;
      thead {
        th {
          color: #85868b;
          background-color: transparent;
          // border-color: #dee2e6;
          font-weight: 500;
          text-align: left;
          font-size: 14px;
          vertical-align: bottom;
          // border-bottom: 1px solid #dee2e6;
          padding: 0.75rem;
          font-variant: tabular-nums;
          list-style: none;
          position: relative;
          @media (max-width: 768px) {
            font-size: 12px;
          }
          div {
            display: flex;
            span {
              text-align: center;
            }
          }
          i {
            align-self: center;
            //width: 20px;
            opacity: 0.5;
            transition: 0.2s;
            font-size: 28px;
            margin-left: auto;
            cursor: pointer;
          }
          &:hover {
            i {
              transition: 0.2s;
              opacity: 1;
              display: inline;
            }
          }
        }
      }

      td {
        padding: 0 5px;
        text-align: left;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
      }
      tbody {
        tr {
          // border-bottom: 1px solid #e8e8e8;
          background-color: white;
          border-radius: 4px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .custom-table {
      & > table > thead > tr > th {
        font-size: 10px;
      }
    }
  }

  @media (max-width: 768px) {
    .custom-table {
      overflow-x: initial;
      & > table {
        margin-top: 0;
        & > thead {
          & > tr > th {
            font-size: 6px;
            line-height: 8px;
            // padding: 5px !important;

            i {
              display: none;
            }

            &:hover i {
              display: none;
            }
          }
        }
        & > tbody {
          & > tr > td {
            height: 30px;
            font-size: 8px;
            // padding: 5px !important;
          }
        }
      }

      &.appointment-table {
        & > table {
          & > thead > tr > th,
          & > tbody > tr > td {
            &:nth-child(1) {
              width: 20px;
              max-width: 20px;
              min-width: 20px;
            }
          }
        }
      }
    }
  }
}
.arrowActive {
  opacity: 1;
}
.arrow {
  font-size: 11px;
  padding-left: 3px;
}
.appointment {
  width: 100% !important;
}
.appointmentWrapper {
  padding: 0 20px;
}
@media (max-width: 450px) {
  .custom-data-table-length {
    select {
      max-width: 90px;
    }
  }
}
@media (min-width: 768px) {
  table {
    // table-layout: fixed;
    border-collapse: collapse;
  }
}

.faded {
  opacity: 0.5;
  cursor: not-allowed;
}
