.notes-wrapper {
  position: relative;
  .notes-input-row {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    padding-top: 10px;
    height: 100%;
    min-height: 50px;

    // .notes-input {
    //   width: calc(100% - 125px);
    // }

    .input-button {
      min-width: 125px;
      max-width: 160px;
    }
  }

  .notes-list-wrapper {
    display: flex;
    flex-direction: column;
    //gap: 0.2rem;
    margin: 0.2rem 0;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    // overflow: scroll;
    // max-height: 400px;

    &.fitContent {
      max-height: fit-content;
    }

    &.empty {
      border: none;
    }

    .note-container {
      padding: 0.5rem 0 0 0.5rem;

      &.show {
        height: auto;
      }

      &.hide {
        //max-height: 120px;
        //overflow: scroll;
      }

      .note-top__block {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 0.5rem;

        .note-text {
          text-align: start;
          // word-break: break-all;
          white-space: break-spaces;
          cursor: pointer;

          @media (min-width: 320px) and (max-width: 425px) {
            font-size: 12px;
          }

          &.cut {
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }

        .note-buttons {
          display: flex;
          gap: 5px;
          position: relative;
          top: 3px;
        }

        .edit-note-input {
          flex: 1;
        }

        .edited-note-save-btn {
          //margin-left: 10px;
          //margin-right: auto;
        }

        .note-icons {
          display: grid;
          grid-template-columns: 28px 28px 28px;
          justify-content: center;
          justify-items: center;
          gap: 5px;
          // min-width: 70px;
          // display: flex;
          // align-items: center;
          // gap: 5px;

          // & > :first-child {
          //   margin-right: 5px;
          //   cursor: pointer;
          //   display: inline-block;
          // }
          // & > :nth-child(2) {
          //   margin-left: 5px;
          //   cursor: pointer;
          //   display: inline-block;
          // }

          // & > :last-child {
          //   margin-left: 5px;
          //   cursor: pointer;
          //   display: inline-block;
          // }
        }
      }

      .note-bottom__block {
        .deleted-text,
        .updated-text,
        .created-text {
          display: flex;
          justify-content: space-between;
          height: 15px;
          i {
            font-size: 10px;
            color: #000000d9;
            opacity: 0.5;
          }
          .deleted-text__tooltip {
            cursor: pointer;
          }
        }
      }
    }
    .note-separator {
      border-bottom: 1px solid #d6d6d6;
      height: 1px;
      margin: 0 auto;
      width: 80%;
    }
    .show-all-notes-btn {
      width: 350px;
    }
  }
}

.arrow__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  width: 100%;
  margin-top: -30px;

  &.up {
    bottom: -16px;
  }

  &.down {
    bottom: -14px;
  }

  .arrow__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    background-color: #4285f4;
    border: 1px solid #4285f4;
    color: #ffffff;
    cursor: pointer;
    font-size: 20px;
  }

  @media (min-width: 320px) and (max-width: 425px) {
    margin-top: -20px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .notes-wrapper {
    //width: 290px;

    & > .notes-input-row > .notes-input > div {
      padding: 0;
      height: 26px;
    }
    & > .notes-list-wrapper > .show-all-notes-btn {
      width: 290px;
    }

    & > .notes-list-wrapper > .note-container > .note-top-block {
      flex-wrap: wrap;
      gap: 1rem;
      & > .edit-note-input {
        width: 100%;
        flex: none;

        div {
          padding: 0;
        }
      }

      & > .edited-note-save-btn {
        width: 75px;
      }
    }
  }
}
