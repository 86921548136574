.appointment {
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  .MuiFormHelperText-marginDense {
    font-size: 9px;
  }

  & .select-appt label {
    font-size: 12px;
    @media screen and (min-width: 1024px) {
      font-size: 16px;
    }
  }
  // Its MUI component
  .appointment-notes > label + div {
    padding: 6px 10px !important;
    & textarea {
      padding: 0 !important;
    }
  }

  /* .reccruing-switcher {
    display: flex;

    span {
      margin-left: 10px;
      align-items: center;
    }
  } */

  .appointment-date {
    // margin-top: 10px;
    // margin-bottom: 25px;
    .time-start,
    .time-end {
      & input::placeholder {
        color: rgba(0, 0, 0, 0.35);
      }
    }
    .time-start {
      position: relative;

      &::before {
        position: absolute;
        content: 'Promised arrival time:';
        left: 0;
        top: -23px;
        font-size: 0.8rem;
        width: 200px;
      }
    }
    .matrix-time__start {
      &::before {
        position: absolute;
        content: 'Schedule time:';
        left: 0;
        top: -23px;
        font-size: 0.8rem;
        width: 200px;
      }
    }
  }

  // .appointment-date,
  .appointment-info {
    // display: flex;
    // justify-content: space-between;
    & > * {
      flex: 0 0 32%;
    }

    // @media (min-width: 1024px) {
    //   & > div > div > label {
    //     font-size: 14px;
    //   }
    //   & > div > label {
    //     font-size: 14px;
    //   }
    // }

    @media (min-width: 768px) and (max-width: 1023px) {
      & > div > div > label {
        font-size: 10px;
      }
      & > div > label {
        font-size: 10px;
      }
    }

    @media (max-width: 767px) {
      & > div > div > label {
        font-size: 8px;
      }
      & > div > label {
        font-size: 8px;
      }
    }
  }

  .appointment-date {
    .MuiInputAdornment-root {
      button {
        padding: 0;
      }
    }
  }

  ////

  .drop-to-schedule {
    &__switcher {
      button {
        margin: 0 5px;
      }
    }

    &__time {
      margin-top: 10px;
      display: flex;
      gap: 10px;
    }
  }

  .reccuring-settings {
    &__interval {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 15px;
      margin-bottom: 1rem;

      & > * {
        flex: 0 0 32%;
      }
    }

    &__dates {
      // display: flex;
      // justify-content: space-between;
      max-width: 240px;

      & > * {
        flex: 0 0 48%;

        .ant-calendar-picker {
          width: 100%;
        }
      }

      .ending-options {
        display: flex;
        flex-direction: column;

        & > * {
          margin-bottom: 10px;
        }
      }

      & + label {
        margin: 5px 0 10px;
      }
    }
  }
}
.drop-to-schedule__time {
  & .orcatec-timepicker {
    width: 100%;
    max-width: 200px;
    & .error {
      display: block;
      text-align: center;
    }
  }
}

// .matrix-time__start,
// .matrix-time__end,
// .time-start,
// .time-end {
//   display: block;
//   width: 100%;
//   max-width: 150px;
// }

.appointment-date {
  display: grid;
  grid-template-columns: auto 270px;
  max-width: 600px;
  gap: 10px;
  align-items: flex-end;
  & .orcatec-timepicker {
    max-width: 160px;
    & .error {
      font-size: 9px;
    }
  }
  & .time-picker_wrapper {
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }
}

.appointment-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}
