.payment-balances {
  .table {
    .table-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background-color: #c4c4c433;
      margin-bottom: 10px;
      border-radius: 4px;
      border: 1px solid #c4c4c433;
      position: relative;

      & > p {
        flex: 1 0 80px;
        padding: 5px 10px;

        &:nth-child(1) {
          flex-grow: 3;
          // position: relative;

          .payment-radio {
            position: absolute;
            left: -30px;

            .ant-radio {
              .ant-radio-inner {
                border-color: #191919;
                border-width: 2px;
                width: 18px;
                height: 18px;
                &::after {
                  background-color: #191919;
                  // width: 10px;
                  // height: 10px;
                  // top: 2px;
                  // left: 2px;
                }
              }
            }
          }
        }
        &:nth-child(2) {
          flex-grow: 5;
        }
        &:nth-child(5) {
          flex: 0 1 100px;
        }
        &:nth-child(6) {
          flex: 0 0 70px;
        }
      }

      &.heading {
        background-color: transparent;
        border: none;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #000000;
        opacity: 0.5;
        white-space: nowrap;
        margin-bottom: 0;
      }

      /* &.remaining-balance {
        p {
          flex: 1 1 70px;

          &:nth-child(2),
          &:nth-child(3) {
            flex: 0 0 10px;
          }
          &:nth-child(4),
          &:nth-child(5) {
            flex: 0 1 64px;
          }
        }
      } */

      .payment-transactions {
        flex-basis: 100%;
        // font-size: 12px;
        // margin-left: 15px;
        // overflow: auto;
        // font-size: 12px;
        // overflow: auto;
        // background-color: white;
        // border-radius: 4px;
        // margin-top: 10px;

        &__row {
          display: flex;

          p {
            padding: 5px;
            flex: 0 0 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.id {
              flex: 0 0 100px;
            }
            &.acc_holder,
            &.processed_by {
              flex: 0 0 130px;
            }
            &.check_number,
            &.acc_number {
              flex: 0 0 90px;
            }
            &.method {
              flex: 0 0 60px;
            }
          }

          &.heading {
            color: #000000;
            opacity: 0.5;
          }
        }
      }
    }
  }

  &.radio {
    .table-row {
      &.canPay {
        &:hover,
        &.active {
          border-color: #191919;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 499px) {
    .table {
      .table-row {
        font-size: 9px;

        &.heading {
          font-size: 6px;

          p {
            white-space: pre-wrap;
          }
        }

        & > p {
          padding: 2px;
          flex: 1 0 60px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:nth-child(1) {
            .payment-radio {
              left: -20px;
              font-size: 10px;

              .ant-radio {
                .ant-radio-inner {
                  width: 12px;
                  height: 12px;
                  border-width: 1.5px;

                  &:after {
                    width: 15px;
                    height: 15px;
                  }
                }
              }
            }
          }
        }

        .payment-transactions {
          // margin-left: 5px;
          font-size: 8px;

          .heading {
            font-size: 6px;
          }

          p {
            padding: 2px;
            flex: 0 0 75px;

            &.id {
              flex: 0 0 60px;
            }
            &.acc_holder,
            &.processed_by {
              flex: 1 0 60px;
            }
            &.acc_number {
              flex: 0 0 60px;
            }
            &.check_number {
              flex: 0 0 30px;
            }
            &.method {
              flex: 0 0 30px;
            }
            &.status {
              flex: 0 0 40px;
            }
          }
        }
      }
    }
  }
}
