@use './ModalProposalSectionTemplateLoadConstants';

.modal-proposal-section-template-load {
  //width: 1078px;
  //border: 0;
  //border-radius: 4px;
  //bottom: auto;
  ////min-height: 10rem;
  //left: 50%;
  ////padding: 2rem;
  //position: fixed;
  //right: auto;
  //top: 50%;
  //transform: translate(-50%,-50%);
  ////min-width: 20rem;
  ////width: 80%;
  ////max-width: 60rem;
  //overflow: hidden;

  .ant-modal-header {
    border-bottom: none;
    .ant-modal-title {
      font-size: 28px;
      font-weight: 500;
    }
  }

  .ant-modal-content {
    overflow: auto;
  }

  .modal-body {
    padding-right: 20px;
    padding-left: 20px;
    label {
      margin-bottom: 0;
      //color: #D6D6D6;
    }
    .MuiPaper-root {
      box-shadow: unset;
    }
    .ant-radio-inner {
      border-color: black;
    }

    .ant-input {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: #191919;
    }
    .ant-input:hover {
      border-color: #4285f4;
    }
    .ant-input:focus {
      box-shadow: unset;
      outline: unset;
    }

    .ant-radio-wrapper:hover .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: black;
    }
    .ant-radio-inner::after {
      background-color: black;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
  .ant-pagination-item-active {
    background: #626ed4;
    border-color: #626ed4;
    color: #fff;
  }
  .load-template-custom-table {
    .ant-select {
      font-size: 12px;
    }

    .custom-select-outlined {
      @extend %customSelectOutlined;
    }

    table {
      @extend %customTableConfig;
      tr > th:nth-child(1),
      tr > td:nth-child(1) {
        max-width: 60px;
        width: 60px;
        text-align: center;
        .ant-radio-wrapper {
          margin-right: 0;
        }
      }
      tr > th:nth-child(2),
      tr > td:nth-child(2) {
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
      tr > th:nth-child(4),
      tr > td:nth-child(4) {
        max-width: 135px;
      }
      tr > th:nth-child(5),
      tr > td:nth-child(5) {
        min-width: 150px;
        max-width: 200px;
        vertical-align: middle;
        .not-my-template {
          height: 1px;
          width: 30%;
          background-color: red;
        }
        .MuiAutocomplete-inputRoot {
          font-size: inherit;
        }
      }
      tr > th:last-child,
      tr > td:last-child {
        width: 80px;
      }
      tbody {
        tr:hover:not(.contract) {
          background: #f2f3fe;
          cursor: pointer;
        }
      }
    }
  }
}
.option-user-for-load-template {
  font-size: 12px;
}

.custom-select-outlined-menu {
  .MuiMenu-list {
    max-height: 200px;
  }
  .MuiMenuItem-root {
    font-size: inherit;
    white-space: normal;
    max-width: fit-content;
  }
}

.confirm-modal > div > div {
  .ant-modal-body {
    text-align: center;
    font-size: 16px;

    .ant-checkbox-wrapper {
      margin-top: 20px;
      margin-left: 20px;
    }

    .sure-modal-text {
      padding: 10px;
    }
  }
}

.chosen {
  background-color: #8080803b !important;
}

/* .proposal-template-shared-option-wrapper {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  width: 100%;
  margin: -6px -16px;
  padding: 6px 16px;

  &-text {
    white-space: nowrap;
    flex: 1;
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}

.proposal-template-shared-option-wrapper-selected {
  background: #e6e8fd;
  border-radius: 4px;
} */
