%totalTableSettings {
  width: 100%;
  tbody {
    tr > td {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.01em;
      color: #000000;
      .MuiTextField-root {
        margin-bottom: 0;
        // input {
        //   font-weight: 500;
        //   font-size: 14px;
        //   letter-spacing: 0.01em;
        //   color: #000000;
        // }
      }
      vertical-align: middle;

      @media (max-width: 670px) {
        font-size: 14px;
      }
    }
    tr > td:first-child {
      text-align: start;
      // padding-right: 70px;

      @media (max-width: 600px) {
        padding-right: 30px;
        font-size: 14px;
      }
    }
    tr > td:last-child {
      width: 120px;
      // min-width: 100px;
      div {
        // width: 100px;
      }
      @media (max-width: 600px) {
        width: auto;
        text-align: right;
      }
    }
    tr:not(:last-child) > td {
      padding-bottom: 5px;
    }
  }
}
.proposal-form-tabs_content-tab_section-wrapper-content {
  width: 100%;
  padding: 0 15px 30px 5px;

  &__labor {
    width: 115px;
    @media (max-width: 600px) {
      width: auto;
    }
  }

  &-items_name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.01em;
    color: #191919;
    flex: 1;
  }
  &-total_table_wrapper:first-child {
    padding: 10px 0 0;
    margin-bottom: 10px;

    @media (max-width: 499px) {
      padding: 10px 80px 0 0;
    }
  }
  &-total_table_wrapper:last-child {
    padding-bottom: 10px;
  }
  &-total_table_wrapper-freeze {
    position: relative;
    padding-left: 15px;
    // padding-right: 15px;
    //background-color: rgba(0, 0, 0, 0.02);
    // margin: 0 -15px 0 -15px;
    z-index: 0;
    & > .cover {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
  &-total_table {
    @extend %totalTableSettings;
    tbody {
      tr > td:first-child {
        text-align: right;
      }
      tr > td:last-child {
        text-align: right;
      }
    }
  }
  &-divider {
    width: calc(100% - 105px);
    min-width: auto;
    margin-left: 25px;
  }
  &-divider-freeze {
    padding: 1.5rem 95px 1.5rem 15px;
    //background-color: rgba(0, 0, 0, 0.02);
    // margin: 0 -15px 0 -15px;
    & > div {
      margin: 0;
    }

    @media (max-width: 499px) {
      padding: 0.5rem 0;
    }
  }

  & > .table-items {
    margin-bottom: 15px;

    & + :not(.table-items) {
      margin-top: -5px;
    }
  }

  .proposal-form-tabs_content-tab_section-wrapper-content-total_table {
    margin-bottom: 10px;
  }
}
