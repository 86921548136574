.media-container {
  padding: 10px;

  .add-media-button {
    margin: 0 10px;
  }

  .property-media,
  .property-media_from_proposals {
    width: 100%;
    background: #f2f3fe;
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 1rem;
    &-tab {
      width: 100%;
      padding-left: 30px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      font-weight: 600;
      font-size: 21px;
      letter-spacing: 0.01em;
      color: #191919;
      margin: 15px 0;
      cursor: pointer;
      & > span:nth-child(1) {
        margin-right: 25px;
      }
      & > span:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        padding: 0 20px;
        font-weight: 500;
        font-size: 12px;
        color: #4285f4;
        background: #4c53ef14;
        border-radius: 4px;
        margin-right: 12.5px;
      }
      & > span:nth-child(3) {
        font-size: 26px;
        font-weight: 500;
        cursor: pointer;
        padding: 0 12.5px;
        &:hover {
          color: #4285f4;
        }
      }
      & > span:last-child {
        margin-left: auto;
      }
      @media (max-width: 499px) {
        font-size: 14px;
        margin: 0;
      }
      @media (max-width: 440px) {
        padding-left: 0;
        & > span:nth-child(2) {
          margin-right: 10px;
        }
        & > span:nth-child(2) {
          margin-right: 5px;
          height: 20px;
          padding: 0 10px;
        }
        & > span:nth-child(3) {
          margin: 0 5px;

          .custom-toggle-btn {
            width: 24px;
            min-width: 24px;
            max-width: 24px;
            height: 24px;
          }
        }
      }
    }

    & > button {
      margin-left: 20px;
    }

    @media (max-width: 499px) {
      padding: 5px;
    }
  }
  span {
    &.proposal-code {
      font-size: 1.2rem;
      display: block;
    }
    &.tab-title {
      font-size: 1rem;
      display: block;
    }
    &.files-title {
      display: block;
      margin-left: 10px;
      font-weight: 500;
      font-size: 14px;
      white-space: nowrap;
      color: #000;
    }
  }

  .btnGroupMediaWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 18px;
    .btnGroupMedia {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 499px) {
      .btnGroupMedia {
        margin: 0;
      }
    }
  }
  .items-container {
    .ant-checkbox-inner::after {
      display: block;
    }
    .img-item {
      display: flex;
      align-items: center;

      margin: 0 20px 20px 0;

      .image-info {
        flex: 0 0 100px;

        .image-container {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          background: white;
          margin-bottom: 2px;

          .remove-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            z-index: 2;

            svg path {
              fill: #f5222d;
            }
            &:hover {
            }
          }

          .img-thumbnail {
            max-width: 100%;
            font-size: 8px;
            cursor: pointer;
          }
        }

        .image-user-info {
          font-size: 8px;
          opacity: 0.5;
          & p {
            text-align: center;
          }
        }
      }

      .ant-checkbox-wrapper {
        position: relative;
        top: -15px;
      }

      .image-description {
        align-self: flex-start;
        flex: 1 1 300px;
        padding-left: 10px;
      }

      .img-checkbox {
        margin-left: 20px;
        margin-right: 20px;
        width: 20px;
        height: 20px;
        padding: 0;
        top: 10px;
        left: 10px;
      }

      .image-name {
        max-width: 172px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        // margin-left: 33px;
        width: 100%;
        text-align: center;
      }
    }
    .img-item:hover {
      .fa-times-circle {
        opacity: 1;
        visibility: visible;
      }
    }
    @media (max-width: 499px) {
      padding: 5px;

      .img-item {
        flex: 0 1 80px;
        margin: 0 10px 10px 0;
        justify-content: space-around;

        .image-container {
          margin-left: 0px;
          margin-bottom: 5px;
          flex: 0 0 50px;
          height: 50px;
        }
        .image-name {
          margin-left: 23px;
          max-width: 50px;
        }
      }
    }
  }
}

.proposal-media__link:hover {
  text-decoration: underline;
}

.items-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 20px;
}

.image-user-info p {
  font-size: 8px;
  text-align: center;
}
.image-info {
  width: 100%;
  max-width: 100px;
  margin-right: 20px;
  position: relative;
  margin-bottom: 2px;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 2;
}
.remove-icon svg path {
  fill: #f5222d;
}

.img-thumbnail {
  max-width: 100%;
  font-size: 8px;
  cursor: pointer;
}
.image-description {
  width: 100%;
}
.proposal-items_wrapper {
  display: none;
}
.media-arrow__bottom {
  margin-left: 30px;
  cursor: pointer;
}
.proposal-title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-proposal {
  border: none;
  background-color: transparent;
}
.empty-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}
