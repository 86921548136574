.tagsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 10px; */
  width: 100%;
}
.tagSelectWrapper {
  width: 100%;
  max-width: 400px;
}

.select {
  width: 100%;
  max-width: 400px;
}
.pickerWrapper {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.chooseColor {
  width: 40px;
  height: 30px;
  border-radius: 6px;
  background-color: #626ed4;
  cursor: pointer;
}
.tags {
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 6px;
}

.optionWrapper {
  margin-bottom: 50px;
}

.menuWrapper {
  margin-bottom: 50px;
}
