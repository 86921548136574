@use '../../../Layouts/AuthLayout/layoutConstants';

.custom-address-autocomplete {
  &-wrapper {
    // @extend %settings;
    position: relative;
    //height: 40px;
  }
  &-dropdown {
    $borderRadius: 4px;
    $wrapperPadding: 20px;
    z-index: 1001;
    width: 100%;
    min-width: 600px;
    position: absolute;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.35);
    border-radius: $borderRadius;

    .search-type {
      display: flex;
      align-items: center;
      margin: 5px 0 5px 20px;

      button {
        margin: 0 20px;
      }
    }

    @media (max-width: 760px) {
      min-width: 100%;
    }
    &-normal {
      top: 30px;
      bottom: unset;

      @media (max-width: 499px) {
        top: 20px;
      }
    }
    &-reverse {
      top: unset;
      bottom: calc(#{100%} + #{5px});
    }
    &-no_table {
      min-width: 100%;
    }
    &-preloader {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4285f4;
    }
    &-no-data {
      padding: 10px 20px;
      font-size: 14px;
      letter-spacing: 0.01em;
      color: #000000;
      opacity: 0.5;
    }
    &-no-address {
      background: rgba(196, 196, 196, 0.3);
      border-radius: 0px 0px $borderRadius $borderRadius;
      padding: 10px $wrapperPadding;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #191919;
      u {
        cursor: pointer;
        white-space: nowrap;
      }
      u:hover {
        color: #4285f4;
      }

      @media (max-width: 499px) {
        padding: 5px 8px;
        font-size: 8px;
      }
    }
  }
}
