.client-modal {
  & > .ant-modal-content {
    & > .ant-modal-header {
      padding: 1rem 1rem 0;
    }
    & > .ant-modal-body {
      padding: 10px;
      & > .formClientWrapper {
        & > .add-or-update-client-form {
          margin: 0;
          .tabss-content {
            // padding: 0;
            & > .clientWrapperMain {
              padding: 10px;

              @media (max-width: 499px) {
                padding: 0 5px;
              }
            }
          }
        }

        .controlButtons {
          margin-bottom: 0;
        }
      }
    }
  }
}
// .nameWrapper {
//   display: flex;
//   margin-bottom: 30px;
// }
// .rowClientName-first {
//   width: 170px;
//   // margin-right: 10px;
// }
.rowClientName-second {
  width: 145px;
}
// .rowClientName-second-relation {
//   width: 220px;
// }
.rowClientName {
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-size: 15px;
    top: 0;
  }
}

.client-modal {
  min-width: 350px;
  max-width: none;
  margin: 5vh auto;

  @media (max-width: 1286px) {
    width: 98vw;
    max-width: 1050px;
  }
  @media (min-width: 1286px) {
    width: 80vw;
    max-width: 1050px;
  }

  @media (max-width: 499px) {
    .modal-body {
      padding: 0;
    }
  }
}
// .client-form-table-container {
//   h5 {
//     .mdi {
//       background-color: #2c2b3c;
//       color: white;
//       border-radius: 90px;
//       margin-left: 5px;
//       font-size: 16px;
//       padding: 0 2px;
//       cursor: pointer;
//     }
//     .fa-plus-circle {
//       //background-color: #2c2b3c;
//       color: #2c2b3c;
//       margin-left: 5px;
//       //font-size: 16px;
//       cursor: pointer;
//     }
//   }
//   td {
//     padding: 5px;
//   }
//   .actions {
//     width: 100px;
//     button {
//       margin: 0 2px;
//       height: 40px;
//       width: 40px;
//       border: none;
//       .fa-check {
//         font-size: 20px;
//       }
//       .mdi-close-circle-outline {
//         font-size: 24px;
//         line-height: 24px;
//         display: flex;
//         justify-content: center;
//         flex-direction: column;
//       }
//     }
//   }
// }
.modalTitle {
  h4 {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 500;
    font-family: inherit;
    line-height: 1.5;
  }
}
.react-tags-wrapper {
  .ReactTags__selected {
    margin-bottom: 20px;
    height: 100px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  input {
    width: 85vw;
    height: 32px;
    font-size: 14px;
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
  span {
    width: 100px;
    background: rgb(56, 148, 248);
    border: 1px solid rgb(56, 148, 248);
    border-radius: 4px;
    padding: 10px;
    height: 35px;
    color: white;
  }
}
.has_error {
  background-image: none !important;
  border-color: #f5222d !important;
}
