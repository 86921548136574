@use '../../../../Layouts/AuthLayout/layoutConstants';

.modal-proposal-template {
  .modal-header {
    border-bottom: none;
    .title {
      font-size: 28px;
      font-weight: 500;
    }
  }
  .modal-body {
    padding-right: 20px;
    padding-left: 20px;
    .MuiAutocomplete-inputRoot {
      font-size: inherit;
    }
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']
      .MuiAutocomplete-input {
      padding: 5.7px 4px;
    }
    .custom-select-outlined {
      background: #ffffff;
      height: 40px;
      border: 1px solid #d6d6d6;
      box-sizing: border-box;
      border-radius: 4px;
      //padding-left: 6px;
      transition: border 0.3s, border-width 0, 3s;

      &:hover {
        border: 1px solid #626ed4;
      }
      & > div {
        height: 100%;
        transform: translate(2px, -2px);
        //padding: 0 11px;
      }
      .MuiInputBase-input {
        font-size: 12px;
        line-height: 18px;

        letter-spacing: 0.01em;

        color: #000000;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 5px;
      }
      .MuiInput-underline:before {
        border-bottom: unset;
      }
    }
    /* .ant-checkbox-inner {
      border: 1px solid #191919;
      border-radius: 4px;
    }

    .ant-checkbox-wrapper {
      & > span:last-child {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #000000;
        opacity: 0.5;
      }
    } */

    /*  label:not(.ant-checkbox-wrapper) {
      margin-bottom: 0;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.01em;
      color: #000000;
      opacity: 0.35;
    } */
    /* .ant-input {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #191919;
    }
    .ant-input:hover {
      border-color: #4285f4;
    }
    .ant-input:focus {
      box-shadow: 0;
      outline: 0;
    } */
    @extend %antCheckbox;

    /* .ant-checkbox-checked .ant-checkbox-inner {
      background-color: black;
      border-color: black;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: black;
    } */
    .current-template-name {
      font-size: smaller;
      margin-top: 10px;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
}

.custom-select-outlined-menu {
  .MuiMenu-list {
    max-height: 200px;
  }
  .MuiMenuItem-root {
    font-size: inherit;
    white-space: normal;
    max-width: fit-content;
  }
}
